<template>
  <div style="padding-top:56px;">
    <div>
      <van-form ref="forms">
        <!-- <div style="color: #4378BE;line-height:40px;padding-left:10px;">{{ $t('tbm.公出注意事项') }}</div> -->
        <van-field readonly
                   clickable
                   v-show="form.cancelFlag != '1'"
                   :required="!authEdit"
                   :disabled="authEdit"
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.beginDate"
                   :label="$t('tbm.开始时间')"
                   :placeholder="$t('tbm.请选择开始时间')"
                   @click="showBeginDate = !authEdit"
                   :rules="[{ required: true, message: this.$t('tbm.请选择开始时间'),trigger: 'change'}]" />

        <van-popup v-model="showBeginDate"
                   v-if="showBeginDate"
                   :disabled="authEdit"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="beginDate"
                               type="datetime"
                               @confirm="beginDateChange"
                               @cancel="showBeginDate=false"
                               :title="$t('tbm.选择开始时间')" />
        </van-popup>

        <van-field readonly
                   clickable
                   v-show="form.cancelFlag != '1'"
                   :required="!authEdit"
                   :disabled="authEdit"
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.endDate"
                   :label="$t('tbm.结束时间')"
                   :placeholder="$t('tbm.请选择结束时间')"
                   @click="showEndDate = !authEdit"
                   :rules="[{ required: true, message: this.$t('tbm.请选择结束时间'),trigger: 'change'}]" />
        <van-popup v-model="showEndDate"
                   v-if="showEndDate"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="endDate"
                               type="datetime"
                               @confirm="endDateChange"
                               @cancel="showEndDate=false"
                               :title="$t('tbm.选择结束时间')" />
        </van-popup>
        <van-field readonly
                   clickable
                   v-show="form.cancelFlag != '1'"
                   :value="form.totalDays"
                   :label="$t('tbm.折合天数')"
                   :disabled="authEdit"
                   :required="!authEdit"
                   is-link
                   input-align="right"
                   placeholder=""
                   :rules="[{ required: true, message: this.$t('tbm.折合天数不能为空'),trigger: 'change'}]" />

        <van-field readonly
                   v-if="form.pkParent"
                   clickable
                   name="picker"
                   :value="cancelFlag"
                   :label="$t('tbm.撤销公出')"
                   :disabled="authEdit"
                   :required="!authEdit"
                   is-link
                   input-align="right"
                   :placeholder="$t('tbm.请选择撤销公出')"
                   @click="showCancelFlagPicker = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择撤销公出'),trigger: 'change' }]" />
        <van-popup v-model="showCancelFlagPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="cancelFlagList"
                      @confirm="onCancelFlagConfirm"
                      @cancel="showCancelFlagPicker = false" />
        </van-popup>

        <van-field v-model="form.reason"
                   style="margin-top:10px;"
                   rows="3"
                   autosize
                   :disabled="authEdit"
                   :label="$t('tbm.公出事由')"
                   type="textarea"
                   :placeholder="$t('tbm.请输入公出事由')"
                   :required="!authEdit"
                   :rules="[{ required: true, message: this.$t('tbm.公出事由不能为空'),trigger: 'change'}]" />
      </van-form>
    </div>
    <approvalButton ref="approvalButton"
                    v-if="form && !authEdit"
                    saveUrl="applyOfficeout"
                    v-model="procConf.woCode"
                    :taskInstanceId="procConf.taskInstanceId"
                    :processName="procConf.processInstanceName"
                    :form="form"
                    :validForm="validateForm"
                    :submitVisable1="submitVisable1"></approvalButton>
  </div>
</template>
<script>
import approvalButton from '@/views/business/components/approvalButton';
import ehrUtils from '@/views/business/libs/EhrUtils.js';
export default {
  components: {
    approvalButton,
    ehrUtils
  },
  props: {
    changeForm: {
      type: Object,
      default: function () {
        return {}
      }
    },
    woCode: {
      type: String,
      default: ''
    },
    processName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showBeginDate: false,
      showEndDate: false,
      showCancelFlagPicker: false,
      form: {
        id: '',
        pkOrg: '',
        pkDept: '',
        pkUser: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        formStatus: '0',
      },
      beginDate: new Date(),
      endDate: new Date(),
      procConf: {
        businessKey: '',
        processInstanceName: '公出申请',
        woCode: 'TbmApplyOfficeout',
        taskInstanceId: '',
        variables: {},
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
      submitVisable1: false,
      deptAndPost: '',
      flag: false,
      authEdit: false,
      cancelFlagList: [
        { value: '1', text: '是' },
        { value: '0', text: '否' },
      ],
      cancelFlag: '',
      isIOS: false
    }
  },
  watch: {
    changeForm: {
      handler (newVal, oldVal) {
        if (newVal && newVal.pkParent) {
          this.form = newVal;
          if (this.form.formStatus === '0') {
            this.authEdit = false;
          } else {
            this.authEdit = true
          }
        }
      },
      deep: true
    },
    'form.cancelFlag': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.cancelFlag = this.cancelFlagList.find(item => item.value == newVal).text;
          if(newVal == '1') {
            this.form.beginDate = '';
            this.form.endDate = '';
            this.form.totalDays = '';
          }
        }
      },
      deep: true
    }
  },
  methods: {
    onCancelFlagConfirm (value) {
      this.form.cancelFlag = value.value;
      this.cancelFlag = value.text;
      this.showCancelFlagPicker = false;
    },
    async validateForm () {
      let activitiData = {
        deptAndPost: '',
        customDeptCode: this.form.customDeptCode
      }
      sessionStorage.setItem('activitiData', JSON.stringify(activitiData))

      let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
      let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
      let officeoutReason=this.form.reason || '';
      if(officeoutReason.length>5){
        officeoutReason=officeoutReason.substring(0.,5)+'……'
      }
      if(this.processName) {
        this.procConf.processInstanceName ='公出变更'+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.form.totalDays+'天'
      } else {
        this.procConf.processInstanceName ='公出申请'+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.form.totalDays+'天'
      }
      return new Promise((resolve, reject) => {
        this.submitForm(resolve);
      })
    },
    submitForm (resolve) {
      this.$refs.forms.validate().then(() => {
        let flag = true;
        delete this.form.formStatusName;
        delete this.form.formColor;
        //校验考勤周期是否封存或确认
        this.checkCycleSealedOrConfirm(this.form.beginDate, this.form.endDate).then((resultCycleSealedOrConfirm) => {
          if (resultCycleSealedOrConfirm.flag) {
            this.$notify({ type: 'danger', message: resultCycleSealedOrConfirm.msg });
            flag = false;
          }
        })

        //校验公出申请时间最多超过公出时间一个工作日
        this.checkApplyOfficeoutConfirm().then((resultApplyOfficeoutConfirm) => {
          if (resultApplyOfficeoutConfirm.flag) {
            this.$notify({ type: 'danger', message: resultApplyOfficeoutConfirm.msg });
            flag = false;
          }
        });

        setTimeout(() => {
          resolve(flag);
        }, 1000)
      });
    },
    async checkCycleSealedOrConfirm (beginDate, endDate) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/attendanceRegist/checkCycleSealedOrConfirm?startDate=' + beginDate + '&endDate=' + endDate,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkApplyOfficeoutConfirm () {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyOfficeout/checkApplyOfficeoutConfirm',
          params: {
            startDate: this.form.beginDate,
            endDate: this.form.endDate,
            applyDate: this.form.applyDate
          }
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    beginDateChange (value) {
      let date = new Date(this.dateChange(value,true));
      if (this.form.endDate && date > this.endDate) {
        this.$notify({ type: 'danger', message: this.$t('tbm.开始日期应小于结束日期') });
        let initBeginDate=this.form.beginDate
        if(this.isIOS){
          this.beginDate = new Date(initBeginDate.replace(/-/g, '/'))
        }else{
          this.beginDate = new Date(initBeginDate)
        }
        return false;
      } else {
        this.beginDate = new Date(this.dateChange(value,true));
        this.form.beginDate = this.dateChange(value,false);
        this.showBeginDate = false;
        if (this.form.beginDate && this.form.endDate) {
          this.setDays();
        }
      }

    },
    endDateChange (value) {
       let date = new Date(this.dateChange(value,true));
      if (this.form.beginDate && date < this.beginDate) {
        this.$notify({ type: 'danger', message: this.$t('tbm.开始日期应小于结束日期') });
        let initEndDate=this.form.endDate;
        if(this.isIOS){
          this.endDate=new Date(initEndDate.replace(/-/g, '/'))
        }else{
          this.endDate=new Date(initEndDate)
        }
        return false;
      } else {
        this.endDate = new Date(this.dateChange(value,true));
        this.form.endDate = this.dateChange(value,false);
        this.showEndDate = false;
        if (this.form.beginDate && this.form.endDate) {
          this.setDays();
        }
      }
    },
    async  setDays (data) {
      var startTime = this.form.beginDate;
      var endTime = this.form.endDate;
      if(this.isIOS){
        startTime = new Date(startTime.replace(/-/g, '/'));
        endTime = new Date(endTime.replace(/-/g, '/'));
      }else{
        startTime = new Date(startTime);
        endTime = new Date(endTime);
      }
      var tableBeginTime = (startTime.getHours() < 10 ? ('0' + startTime.getHours()) : startTime.getHours()) + ':' + (startTime.getMinutes() < 10 ? ('0' + startTime.getMinutes()) : startTime.getMinutes());
      // var tableBeginTime = startTime.getHours() + ':' + (startTime.getMinutes() < 10 ? ('0' + startTime.getMinutes()) : startTime.getMinutes());
      var tableEndTime = endTime.getHours() + ':' + (endTime.getMinutes() < 10 ? ('0' + endTime.getMinutes()) : endTime.getMinutes());
      startTime = startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ' ' + startTime.getHours() + ':' + startTime.getMinutes();
      endTime = endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ' ' + endTime.getHours() + ':' + endTime.getMinutes();
      var dateResult = this.getAllDate(startTime.split(' ')[0], endTime.split(' ')[0]);

      this.form.applyOfficeoutDetails = [];
      const result = await this.getWorkHourRange1(this.form.beginDate, this.form.endDate);
      // true 这说明有重复日期申请
      if (result.data.ext.hasRepeats) {
        this.$notify({ type: 'danger', message: '申请公出 出现重复' });
        this.form.totalDays = '';
        return false;
      }
      // const resultDate = await this.getApplyPrescribedDate(this.form.endDate);
      // if (resultDate.data.entity) {
      //     if (this.judgeDate(resultDate.data.entity) > 0) {
      //         this.$message({
      //             showClose: true,
      //             message: '很抱歉，您已经超过规定申请时间',
      //             type: 'error'
      //         });
      //         this.form.totalDays = '';
      //         return false;
      //     }
      // }
      var totalDay = 0.0;
      if (result.data.ext.workhouRangerlist.length >= 0) {
        var hourRangeData = result.data.ext.workhouRangerlist;
        for (var i = 0; i < dateResult.length; i++) {
          var obj = {};
          obj.dateDetail = dateResult[i];
          //获取请假日期对应的 班时区间
          var hourRangeData1 = this.filterByDay(hourRangeData, obj.dateDetail)
          if (dateResult.length === 1) {
            obj.rangeFlag = '1';
            obj.rangeName = '自定义';
            obj.beginTime = tableBeginTime;
            obj.endTime = tableEndTime;
            obj.realDays = await this.getRealDays(tableBeginTime, tableEndTime, hourRangeData1, '1');//请假结束 是同一天
          } else {
            if (i === 0) {
              obj.rangeFlag = '1';
              obj.rangeName = '自定义';
              obj.beginTime = tableBeginTime;
              obj.endTime = '--';
              obj.realDays = await this.getRealDaysConstart(tableBeginTime, '', hourRangeData1);
            } else if (i === (dateResult.length - 1)) {
              obj.beginTime = '--'
              obj.endTime = tableEndTime
              obj.rangeFlag = '1';
              obj.rangeName = '自定义';
              obj.realDays = await this.getRealDaysConstEnd('', tableEndTime, hourRangeData1);
            } else {
              obj.rangeFlag = '0';
              obj.rangeName = '全天';
              obj.beginTime = '--';
              obj.endTime = '--';
              obj.realDays = await this.getRealDaysNotCont('', '', hourRangeData1);
            }
          }
          var flag = false;
          for (var j = 0; j < hourRangeData.length; j++) {
            if (hourRangeData[j].DATE_DETAIL.startsWith(obj.dateDetail)) {
              flag = true
            }
          }
          if (!flag) {
            obj.realDays = 0.0
          }
          totalDay += parseFloat(obj.realDays)
          this.form.applyOfficeoutDetails.push(obj);

        }
        if (totalDay == '0.0') {
          this.$notify({ type: 'danger', message: this.$t('tbm.天数不能为空') });
          this.form.applyOfficeoutDetails = [];
          return false;
        }
        else {
          this.form.totalDays = totalDay.toFixed(1);
        }

      }

    },
    filterByDay (data, day) {
      return data.filter(item => item.DATE_DETAIL.startsWith(day))
    },
    getRealDays (applyStartTime, applyEndTime, hourRangeData, type) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间、
        if (hourRangeData.length == 1) {
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            //请假结束大于下班时间
            if (endHours > 0) {
              return resultHours = ((hourRangeData[i].REAL_HOURS - startHours) / hourRangeData[i].REAL_HOURS).toFixed(1)
            } else {
              return resultHours = (this.getHour(applyStartTime, applyEndTime) / hourRangeData[i].REAL_HOURS).toFixed(1)
            }
          } else {
            //请假结束大于下班时间
            if (endHours > 0) {
              return resultHours = (this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME) / hourRangeData[i].REAL_HOURS).toFixed(1)
            } else {
              return resultHours = ((hourRangeData[i].REAL_HOURS - this.getHour(applyEndTime, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
            }
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            //请假结束大于下班时间
            if (endHours > 0) {
              resultHours += parseFloat(hourRangeData[i].REAL_HOURS - startHours)
            } else {
              resultHours += parseFloat(this.getHour(applyStartTime, applyEndTime))
            }
          } else {
            //请假结束大于下班时间
            if (endHours > 0) {
              resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME))
            } else {
              resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime))
            }
          }
          if (resultHours < 0) {
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            return (resultHours / totalHousr).toFixed(1)
          }

        }
      }
    },
    getRealDaysConstart (applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间、
        if (hourRangeData.length == 1) {
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            return (parseFloat(this.getHour(applyStartTime, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          } else {
            return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            resultHours += parseFloat(this.getHour(applyStartTime, hourRangeData[i].END_TIME));
          } else {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));
          }
          if (resultHours < 0) {
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            return (resultHours / totalHousr).toFixed(1)
          }

        }
      }
    },
    getRealDaysConstEnd (applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间、
        if (hourRangeData.length == 1) {
          //请假结束开始大于始下班时间
          if (endHours > 0) {
            return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }//请假结束小于上班结束时间
          else {
            return ((parseFloat(hourRangeData[i].REAL_HOURS) - parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime))) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (endHours > 0) {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));
          } else {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime));
          }
          if (resultHours < 0) {
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            return (resultHours / totalHousr).toFixed(1)
          }


        }
      }

    },
    getRealDaysNotCont (applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间、
        if (hourRangeData.length == 1) {
          return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)

        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));

          if (i === (hourRangeData.length - 1)) {
            return (resultHours / totalHousr).toFixed(1)
          }


        }
      }

    },
    getHour (s1, s2) {
      var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
      s1 = new Date((reDate.test(s1) ? s1 : '2020-1-1 ' + s1).replace(/-/g, '/'));
      s2 = new Date((reDate.test(s2) ? s2 : '2020-1-1 ' + s2).replace(/-/g, '/'));
      var ms = s2.getTime() - s1.getTime();
      if (ms < 0) return 0;
      return (ms / 1000 / 60 / 60).toFixed(1);
    },
    getAllDate (start, end) {
      let dateArr = []
      let startArr = start.split('-')
      let endArr = end.split('-')
      let db,de
      let nowDate=ehrUtils.getNowDate();
      if(this.isIOS){
        nowDate =nowDate.replace(/-/g, '/')
      }
      db = new Date(nowDate)
      de = new Date(nowDate)
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2])
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2])
      let unixDb = db.getTime()
      let unixDe = de.getTime()
      let stamp
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(this.format(new Date(parseInt(stamp))))
        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    format (time) {
      let ymd = ''
      let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1))
      let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate())
      ymd += time.getFullYear() + '-'; // 获取年份。
      ymd += mouth + '-'; // 获取月份。
      ymd += day; // 获取日。
      return ymd; // 返回日期。
    },
    getWorkHourRange1 (tableBeginTime, tableEndTime) {
      // 增加判断，防止页面报错
      var isReturn = false;
      let apporval=this.$refs['approvalButton'].$refs['approval']
      if (apporval != undefined) {
        isReturn = apporval.reverseBtn;
      }
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOfficeout/getWorkhourRangebyDate',
        params: {
          beginTime: tableBeginTime,
          endTime: tableEndTime,
          isReturn: isReturn,  //用于验证 被退回状态下,日期重复问题
          pkOrg: this.form.pkOrg,
          id: this.form.id
        }
      });
    },
    //flag 用于区分 是修改显示值,还是value
    dateChange (value,flag) {
      let year = new Date(value).getFullYear();
      let month = (new Date(value).getMonth() + 1) < 10 ? '0'+(new Date(value).getMonth() + 1) :(new Date(value).getMonth() + 1);
      let date = new Date(value).getDate() < 10 ? '0'+new Date(value).getDate() :new Date(value).getDate();
      let hour = new Date(value).getHours() < 10 ? '0' + new Date(value).getHours() :new Date(value).getHours();
      let minutes = new Date(value).getMinutes() < 10 ? '0' + new Date(value).getMinutes() :new Date(value).getMinutes();
      let seconds = new Date(value).getSeconds() <10 ? '0'+new Date(value).getSeconds()  :new Date(value).getSeconds() ;
      if(flag){
        if(this.isIOS){
          return year + '/' + month + '/' + date + ' ' + hour + ':' + minutes + ':' + seconds;
        }else{
          return year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconds;
        }
      }else{
        return year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconds;
      }



    },

    backFillEditData (flag) {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOfficeout/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(async res => {
        let backData = res.data.entity;
        if (backData) {
          this.form = backData;
          sessionStorage.setItem('formData', JSON.stringify(this.form));
          if(this.isIOS){
            let endDate =backData.endDate.replace(/-/g, '/')
            let beginDate=backData.beginDate.replace(/-/g, '/')
            this.endDate = new Date(endDate);
            this.beginDate = new Date(beginDate);
          }else{
            this.endDate = new Date(backData.endDate);
            this.beginDate = new Date(backData.beginDate);
          }
        }
        this.deptAndPost = parseInt(res.data.ext.deptAndPost)

        if (backData.formStatus === '0') {
          this.authEdit = false;
        } else {
          this.authEdit = true
        }
        if (this.$route.query.type) {
          this.authEdit = false;
        }
      });
    },
    AndroisIos(){
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
       this.isIOS=false
      } else if (isIOS) {
        this.isIOS=true
      }
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    this.AndroisIos();
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    if (this.woCode) {
      this.procConf.woCode = this.woCode;
    }
    this.form.pkDept = JSON.parse(localStorage.getItem('userInfo')).deptId;
    this.form.pkOrg = JSON.parse(localStorage.getItem('userInfo')).companyId;
    this.form.pkUser = JSON.parse(localStorage.getItem('userInfo')).id;
    let year = (new Date().getFullYear());
    let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
    let date = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
    var timestamp = new Date().getTime().toString().substring(6, 12);
    var formNo = 'GC' + year + timestamp;
    this.form.formNo = formNo;
    this.form.applyDate = year + '-' + month + '-' + date;
    if (JSON.parse(sessionStorage.getItem('formData'))) {
      this.submitVisable1 = true;
    }
    let form = this.$route.params.form || JSON.parse(sessionStorage.getItem('formData')) || null;
    if (this.changeForm.pkParent) {
      form = this.changeForm;
      if(!form.cancelFlag) {
        form.cancelFlag = '0'
      }
      this.cancelFlag = this.cancelFlagList.find(item => item.value == form.cancelFlag).text;
    }
    sessionStorage.removeItem('formData');
    if (form) {
      this.form = form;
      if (form.formStatus === '0') {
        this.authEdit = false;
      } else {
        this.authEdit = true
      }
      if (this.$route.query.type) {
        this.authEdit = false;
      }
    } else if (this.form.id) {
      this.backFillEditData(true);
    };
  },

}
</script>
